import React from 'react'
import { useHistory } from "react-router-dom";


import './CustomTable.style.css'


const CustomTable = (props) => {

    const { titles, items } = props
    let history = useHistory();

    return (

        <table className="table mb-0">
            <thead className="bg-light">
                <tr>
                    {Array.isArray(titles) && titles.length > 0 &&
                        titles.map(title => (
                            <th scope="col" className="border-0">
                                {title}
                            </th>
                        )
                        )}
                </tr>
            </thead>
            <tbody >
                {Array.isArray(items) && items.length > 0 && items.map((item, index) => (
                    <tr style={{ cursor: 'pointer' }}
                        key={'proc-item-' + index} onClick={() => history.push(`/details/${item._id}`)}>
                        <td>{item.clientReference}</td>
                        <td>{item.clientName}</td>
                        <td>{item.boarding.origin}</td>
                        <td>{item.boarding.destination}</td>
                        <td>{item.microStatus}</td>
                    </tr>
                ))}

            </tbody>
        </table >

    )
}



export default CustomTable