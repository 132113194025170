import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col } from "shards-react";

const DetailItem = ({ title, info, className, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    "text-md-left",
    "mb-sm-0"
  );

  return (
    <Col xs="12"  className={classes} { ...attrs }>
      <small className="text-uppercase">{title}</small>
      <p className="text-uppercase page-title" 
      style={{fontWeight: 'bold'}}>{info}</p>
    </Col>
  )
};

DetailItem.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page info.
   */
  info: PropTypes.any
};

export default DetailItem;
