import React from 'react'
import { Row, Card, CardBody, NavLink } from "shards-react";
import './FollowUps.style.css'
import moment from "moment";
import { SubTitle } from '../../common/CommonComponents';


const FollowUps = (props) => {

    const { info } = props

    
    return (
        <Card small className="card-post mb-4">
            <CardBody>
                <SubTitle title="Acompanhamentos" subtitle="Todos os" className="col-md-12" />
                <NavLink className="back-button" onClick={() => props.onClick('details')}>
                    <i className="material-icons text-muted ">keyboard_backspace</i>
                </NavLink>
                <Row>
                    <div className="rightbox">
                        <div className="rb-container">
                            <ul className="rb">
                                {info.map((follow, index) => {
                                    return (
                                        <li key={"follow-"+index} className="rb-item" ng-repeat="itembx">
                                            <small>Atualizado em: {moment.utc(follow.date).format("DD MMM YYYY")}</small>
                                            <p className="text-mutted">{follow.description}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </Row>
            </CardBody>
        </Card>
    )
}


export default FollowUps