import React from 'react'
import { Card, CardBody } from "shards-react";
import { SubTitle } from '../../common/CommonComponents';
import { Cargo, HouseMeasures, Knowledge } from '../DetailsComponents';

import './DetailsCard.style.css'




const DetailsCard = (props) => {

    const { info } = props

    return (
        <Card small className="card-post mb-4">
            <CardBody>
                <SubTitle title="Detalhadas do Processo" subtitle="Informações" className="col-md-12" />
                {/** Informações da Carga */}
                <Cargo info={info.boarding} />
                {/** Informações Medidas house */}
                <HouseMeasures info={info.boarding.cargo} />
                <Knowledge info={info.boarding.knowledge} />

            </CardBody>
        </Card>
    )
}


export default DetailsCard