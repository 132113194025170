import React from 'react'
import { Row, Col, Card, CardHeader, CardBody, NavLink } from "shards-react";
import { SubTitle } from '../../common/CommonComponents';

import './Documents.style.css'


const Documents = (props) => {

    const { info } = props

    return (
        <Row>
            <Col>
                <Card small className="mb-4 p-0">
                    <CardHeader className="border-bottom">
                        <SubTitle title="Documentos" subtitle="Todos os" className="col-md-12" />
                        <NavLink className="back-button" onClick={() => props.onClick('details')}>
                            <i className="material-icons text-muted ">keyboard_backspace</i>
                        </NavLink>
                    </CardHeader>
                    <CardBody className="pb-3">
                        <table className="table mb-0">
                            <thead className="bg-light">
                                <tr>
                                    <th scope="col" className="border-0">
                                        Arquivo
                                    </th>
                                    <th scope="col" className="border-0">
                                        Grupo
                                    </th>
                                    <th scope="col" className="border-0">
                                        Ações
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {info.map((doc, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><span className="text-muted" >{doc.fileName}</span></td>
                                            <td><span className="text-muted" >{doc.group}</span></td>
                                            <td>
                                                <NavLink href={doc.url} rel="noopener noreferrer" target="_blank" title="Download">
                                                    <i className="material-icons text-muted ">download</i>
                                                </NavLink>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}


export default Documents