import React from 'react'
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Badge
} from "shards-react";

import './ExcerptCard.style.css'

import moment from "moment";
import 'moment/locale/pt-br';
import { DetailItem } from '../DetailsComponents';

const ExcerptCard = (props) => {


    const { info } = props

    return (
        <Card small className="card-post mb-4">
            <CardHeader className="border-bottom">
                <div className="origin-destination">
                    <div >
                        <span className="text-uppercase subtitle">Origem</span>
                        <h6 className="title">{info.boarding.origin}</h6>
                    </div>
                    <i className="material-icons transportation-icon">directions_boat</i>
                    <div >
                        <span className="text-uppercase subtitle">Destino</span>
                        <h6 className="title">{info.boarding.destination}</h6>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center">
                    <Badge pill className={`card-post__category bg-info`} >
                        Aguardando {info.microStatus}
                    </Badge>
                </div>
                <div className="d-flex flex-column mt-1" style={{ textAlign: 'right' }} >
                    <small className="text-muted" title="ETA">ETA {info.boarding.estimatedCargoUnload ? moment.utc(info.boarding.estimatedCargoUnload).format("DD MMM YYYY") : "-"}</small>
                </div>
                <Row className="mt-3">
                    <DetailItem title="BL Master" className="p-mb-0 p-mt-2"
                        info={info.boarding.knowledge.blMaster ? info.boarding.knowledge.blMaster.identification : ''} />
                    <DetailItem title="CE Master" className="p-mb-0 p-mt-2"
                        info={info.boarding.knowledge.blMaster ? info.boarding.knowledge.blMaster.ceIdentification : ''} />
                </Row>
            </CardHeader>
            <CardBody>
                <div className="d-flex flex-column justify-content-center">
                    <Row >
                        <DetailItem title="Cliente" className="p-mb-0 p-mt-2"
                            info={info.clientName} />
                        <DetailItem title="Shipper" className="p-mb-0 p-mt-2"
                            info={info.shipper} />
                        <DetailItem title="Consignee" className="p-mb-0 p-mt-2"
                            info={info.consignee} />
                        <DetailItem title="Notify" className="p-mb-0 p-mt-2"
                            info={info.notify} />
                    </Row>
                </div>
            </CardBody>
            {/* 
            <CardFooter className="border-top d-flex flow-row " style={{ justifyContent: "space-between" }}>
                <Button outline size="sm" theme="primary" className="mb-2 mr-1"
                    onClick={() => props.onClick('followups')}>
                    Acompanhamentos
                </Button>
                <Button outline size="sm" theme="secondary" className="mb-2 mr-1"
                    onClick={() => props.onClick('documents')}>
                    Documentos
                </Button>
            </CardFooter>
        */}
        </Card>
    )
}


export default ExcerptCard