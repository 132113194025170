import React, { useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup
} from "shards-react";




import '../../assets/custom-style.css'

//Imports de componentes criados pela equipe Next
import Kanban from "../../components/kanban/Kanban.component";
import { getProcesses, retrieveUserInformation } from "../../services";
import {PageTitle, Loading, CardTotals, CustomTable} from "../../components/common/CommonComponents";




const Overview = () => {

  const [loading, setLoading] = React.useState(false)
  const [viewMode, setViewMode] = React.useState('table')
  const [processes, setProcesses] = React.useState([])
  const [processesTotals, setProcessesTotals] = React.useState({
    pre: 0,
    transito: 0,
    desembarque: 0
  })

  const [loggedUser] = React.useState(retrieveUserInformation())

  useEffect(() => {
    setLoading(true)
    getAllProcesses()
  }, [])

  const getAllProcesses = async () => {
    try {
      const criteria = []
      //qual cnpj
      criteria.push({
        broker: loggedUser._id
      })
      //removendo status Fora do Kanban
      criteria.push({
        kanbanStatus: { "$nin": ["Fora do Kanban", "Concluído"] }
      })
      //removendo micro status Novo Processo
      criteria.push({
        microStatus: { "$nin": ["Novo Processo"] }
      })
      //removendo "arquivados"
      criteria.push({
        "$or": [
          { doneAt: { "$exists": false } }
        ]
      })
      await getProcesses(JSON.stringify({ "$and": criteria }), '-issues, clientReference')
        .then(resp => {
          setLoading(false)
          if (resp.data) {
            setProcesses(resp.data.items)
            setProcessesTotals({
              ...processes,
              pre: resp.data.items.filter(p => { return p.kanbanStatus === 'Pré embarque' }).length,
              transito: resp.data.items.filter(p => { return p.kanbanStatus === 'Em trânsito' }).length,
              desembarque: resp.data.items.filter(p => { return p.kanbanStatus === 'Desembarcado' }).length
            })
          }
        })
        .catch(error => {
          console.error(error);
          setLoading(false)
          alert("Ocorreu um erro ao se comunicar com o servidor");
        })

    } catch (error) {
      console.error(error);
      setLoading(false)
      alert("Ocorreu um erro ao se comunicar com o servidor");
    }
  }

  return (

    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Visão Geral" subtitle="Dashboard" className="text-sm-left mb-3" />
        <div className="change-view">
          <ButtonGroup>
            <Button outline size="sm" theme="secondary" className={viewMode === 'kanban' ? 'activated' : 'deactivated'}
              onClick={() => setViewMode('kanban')}>
              <i className="material-icons">dashboard</i>
            </Button>
            <Button outline size="sm" theme="secondary" className={viewMode === 'table' ? 'activated' : 'deactivated'}
              onClick={() => setViewMode('table')}>
              <i className="material-icons">list</i>
            </Button>
          </ButtonGroup>
        </div>
      </Row>

      {loading ?
        <Loading number={2} />
        :
        <>
          {/* TotalCards */}
          <Row>
            <Col className="col-lg mb-4" key='card-pre' >
              <CardTotals
                variation="1"
                title="Pré-embarque"
                value={processesTotals.pre}
              />
            </Col>
            <Col className="col-lg mb-4" key="card-transito" >
              <CardTotals
                variation="1"
                title="Em trânsito"
                value={processesTotals.transito}
              />
            </Col>
            <Col className="col-lg mb-4" key="card-desembarque" >
              <CardTotals
                variation="1"
                title="Desembarque"
                value={processesTotals.desembarque}
              />
            </Col>
          </Row>

          {/* Table View */}
          { viewMode === 'table' &&
            <Row>
              <Col>
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Processos</h6>
                  </CardHeader>
                  <CardBody className="p-0 pb-3">
                    <CustomTable 
                      title={['Ref. Client', 'Cliente', 'Origem', 'Destino', 'Status']} 
                      items={processes}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }

          {/* Card View */}
          { viewMode === 'kanban' &&

            <Kanban processes={processes} />

          }
        </>
      }


    </Container>
  )
};

Overview.propTypes = {

};

Overview.defaultProps = {

};




export default Overview;
