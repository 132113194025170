import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col } from "shards-react";

const SubTitle = ({ title, subtitle, className, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    "text-md-left",
    "mb-sm-0"
  );

  return (
    <Col xs="12" sm="6" className={classes} { ...attrs }>
      <small className="text-uppercase">{subtitle}</small>
      <h5 className="text-uppercase page-title">{title}</h5>
    </Col>
  )
};

SubTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.any
};

export default SubTitle;
