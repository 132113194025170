import React, { useEffect } from "react";
import { useParams } from 'react-router-dom'
import PropTypes from "prop-types";
import { Container, Row, Col, Nav, NavItem, NavLink } from "shards-react";

import PageTitle from "../../components/common/PageTitle";

//Imports de componentes criados pela equipe Next
import ExcerptCard from "../../components/details/ExcerptCard/ExcerptCard.component";
import DetailsCard from "../../components/details/DetailsCard/DetailsCard.component";
import FollowUps from "../../components/details/FollowUps/FollowUps.component";
import Documents from "../../components/details/Documents/Documents.component";
import Loading from "../../components/common/Loading.component";
import DocumentApproval from "../../components/details/DocumentApproval/DocumentApproval.components";
import moment from 'moment'
import { getOneProcess, getOneCompany } from "../../services";

import './ProcessDetails.style.css'
import Financials from "../../components/details/Financials/Financials.component";

const ProcessDetails = () => {

  const { id } = useParams()
  const [currentProcess, setCurrentProcess] = React.useState()
  const [view, setView] = React.useState('details')
  const [issuesToSolve, setIssuesToSolve] = React.useState([])
  const [currentCompany, setCurrentCompany] = React.useState()
  const [responsible, setResponsible] = React.useState()

  useEffect(() => {
    if (currentProcess) {
      let responsibles = currentProcess.responsibles.filter(e => { return e.role === "OPERACIONAL" })
      setResponsible(responsibles[0])
    }

  }, [])

  const changeRightPanelView = (text) => {
    setView(text)
  }

  async function getProcess() {
    await getOneProcess(id)
      .then(r => {
        setCurrentProcess(r.data)
        getCompany(r.data.clientDocument)
        if (r.data.issues)
          handleIssuesToSolve(r.data.issues)
      })
      .catch(e => console.log("erro", e))
  }

  async function getCompany(cnpj) {
    await getOneCompany(cnpj.replace(/\D/g, ''))
      .then(r => {
        if (r.data)
          setCurrentCompany(r.data)
      })
      .catch(error => console.error("Error on get company", error))
  }

  function handleIssuesToSolve(issues) {
    let tempIssues = issues.filter(i => {
      return moment(new Date()).diff(moment(i.createdAt), 'hours') < 24
    })
    setIssuesToSolve(tempIssues)
  }

  useEffect(() => {
    getProcess()
  }, [])




  return (
    <>
      { !currentProcess ?
        <Loading number={6} />
        :
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle title={currentProcess.clientReference} subtitle="Detalhes do processo" className="text-sm-left mb-3" />
          </Row>

          {/* First Row */}
          <Row>
            {/* First Col */}
            <Col className="col-md-4 col-sm-12 mb-4" >
              <ExcerptCard info={currentProcess} onClick={(e) => { changeRightPanelView(e) }} />
            </Col>
            {/* Second Col */}
            <Col className="col-md-8 col-sm-12 mb-4" >
              {currentCompany && currentCompany.broker && currentCompany.broker.permissions && currentCompany.broker.permissions.includes('Liberar documentos')
                && issuesToSolve && issuesToSolve.length > 0 &&
                <DocumentApproval issues={issuesToSolve}
                  processNumber={currentProcess.processNumber}
                  responsible={responsible}
                  refClient={currentProcess.clientReference}
                />
              }
              <div className="details-action-bar">
                <Nav pills>
                  <NavItem>
                    <NavLink active={view === 'details'} className="details-navlink"
                      onClick={() => changeRightPanelView('details')}> Detalhes </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink active={view === 'followups'} className="details-navlink"
                      onClick={() => changeRightPanelView('followups')}>Acompanhamentos</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink active={view === 'documents'} className="details-navlink"
                      onClick={() => changeRightPanelView('documents')}>Documentos</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink disabled={currentProcess.kanbanStatus !== 'Desembarcado'}
                      active={view === 'financials'} className="details-navlink"
                      onClick={() => changeRightPanelView('financials')}> Financeiro </NavLink>
                  </NavItem>
                </Nav>
              </div>

              {view === 'details' &&
                <DetailsCard info={currentProcess} />
              }
              {view === 'followups' &&
                <FollowUps info={currentProcess.followUps} onClick={(e) => { changeRightPanelView(e) }} />
              }
              {view === 'documents' &&
                <Documents info={currentProcess.documents} onClick={(e) => { changeRightPanelView(e) }} />
              }
              {view === 'financials' &&
                <Financials financials={currentProcess.financials}
                  payments={currentProcess.documents.filter(doc => { return doc.group.includes('BOLETO') })}
                  onClick={(e) => { changeRightPanelView(e) }}
                  processNumber={currentProcess.processNumber}
                  responsible={responsible} />
              }
            </Col>
          </Row>
        </Container>
      }
    </>
  )
};

ProcessDetails.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};




export default ProcessDetails;
