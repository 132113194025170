import React from 'react'
import { Row, Col } from "shards-react";
import { SubTitle } from '../../../common/CommonComponents';
import { DetailItem } from '../../DetailsComponents';


const HouseMeasures = (props) => {

    const { info } = props

    return (
        <>
            <SubTitle md="12" title="Medidas house" className="h6-subtitle mt-5" />
            <Row>
                {info && info.houseMeasures &&
                    <>
                        <Col md="3" sm="6" xs="12" >
                            <DetailItem className="p-mb-0 p-mt-2"
                                title="Peso bruto" info={info.houseMeasures.grossWeight} />
                        </Col>
                        <Col md="3" sm="6" xs="12" >
                            <DetailItem className="p-mb-0 p-mt-2"
                                title="m³" info={info.houseMeasures.cubicMeters} />
                        </Col>
                        <Col md="3" sm="6" xs="12" >
                            <DetailItem className="p-mb-0 p-mt-2"
                                title="Volumes" info={info.houseMeasures.volumeQuantity} />
                        </Col>
                        <Col md="3" sm="6" xs="12" >
                            <DetailItem className="p-mb-0 p-mt-2"
                                title="Considerado" info={info.houseMeasures.consideredWeight} />
                        </Col>
                    </>
                }
            </Row>
        </>
    )

}


export default HouseMeasures