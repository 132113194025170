import api from "./api";


export const signIn = async dto => {
    let tokenResult = await api.post("users/authenticate", dto);
    return tokenResult;
};

export const persistUserToLocalStorage = async (token) => {
    localStorage.setItem("cpb-token", token.accessToken);
    localStorage.setItem("cpb-userinfo", JSON.stringify(token));
    return;
}

export const retrieveTokenFromLocalStorage = () => {
    return localStorage.getItem("cpb-token");
}

export const retrieveUserInformation = () => {
    let user = localStorage.getItem("cpb-userinfo");
    if (user)
        return JSON.parse(user);
    return undefined;
}

export const isAuthenticated = () => {
    const token = retrieveTokenFromLocalStorage();
    if (token !== undefined && token !== null && token) {
        return true;
    }
    return false;
};