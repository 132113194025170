import React from 'react'
import { Row, Col } from "shards-react";
import { SubTitle } from '../../../common/CommonComponents';
import DetailItem from '../../DetailItem/DetailItem.component';

const Knowledge = (props) => {

    const { info } = props

    return (
        <>
            <SubTitle title="Conhecimento" subtitle={<hr />} className="mt-5" />
            <SubTitle md="12" title="BL House" className="h6-subtitle" />
            <Row>
                {info && info.bls &&
                    info.bls.map((bl, index) => (
                        <React.Fragment key={index}>
                            <Col md="3" sm="6" xs="12" >
                                <DetailItem title="Número" info={bl.identification} />
                            </Col>
                            <Col md="3" sm="6" xs="12" >
                                <DetailItem title="CE House" info={bl.deconsolidation} />
                            </Col>
                            {bl.cargoItems &&
                                bl.cargoItems.map((item, idx) => (
                                    <React.Fragment key={idx}>
                                        <SubTitle md="12" title="Items da Carga" className="h6-subtitle" style={{ marginLeft: '1rem' }} />
                                        <Row className="pl-3 pr-3">
                                            <Col md="3" sm="6" xs="12" >
                                                <DetailItem className="p-mb-0 p-mt-2"
                                                    title="Container" info={item.identification} />
                                            </Col>
                                            <Col md="3" sm="6" xs="12" >
                                                <DetailItem className="p-mb-0 p-mt-2"
                                                    title="Peso bruto" info={item.grossWeight} />
                                            </Col>
                                            <Col md="3" sm="6" xs="12" >
                                                <DetailItem className="p-mb-0 p-mt-2"
                                                    title="m³" info={item.measurement} />
                                            </Col>
                                            <Col md="3" sm="6" xs="12" >
                                                <DetailItem className="p-mb-0 p-mt-2"
                                                    title="Free time" info={item.freeTime} />
                                            </Col>
                                            <Col md="3" sm="6" xs="12" >
                                                <DetailItem className="p-mb-0 p-mt-2"
                                                    title="Embalagem" info={item.packageType} />
                                            </Col>
                                            <Col md="3" sm="6" xs="12" >
                                                <DetailItem className="p-mb-0 p-mt-2"
                                                    title="Quantidade" info={item.quantity} />
                                            </Col>
                                            <Col md="3" sm="6" xs="12" >
                                                <DetailItem className="p-mb-0 p-mt-2"
                                                    title="Equipamento" info={item.equipament} />
                                            </Col>
                                            <Col md="3" sm="6" xs="12" >
                                                <DetailItem className="p-mb-0 p-mt-2"
                                                    title="Lacre" info={item.seal} />
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                ))}

                        </React.Fragment>
                    ))}
            </Row>
        </>
    )

}


export default Knowledge