import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Card, CardHeader, CardBody, NavLink, Button } from "shards-react";
import { toast, ToastContainer } from "react-toastify";

import moment from 'moment'

import './Financials.style.css'
import { SubTitle, Uploader } from '../../common/CommonComponents';
import RequestValueReview from './ResquestValueReview/RequestValueReview.component';

import { updateReceipt } from '../../../services'



const Financials = (props) => {

    const { id } = useParams()
    const { financials, payments } = props
    const [urlPaymentSlip, setUrlPaymentSlip] = React.useState('')
    const [review, setReview] = React.useState({
        askReview: false,
        sent: false
    })

    const [receipt, setReceipt] = React.useState({
        show: false,
        url: '',
        invoice: ''
    })

    /**
     * Como os boletos são arquivos separados no HeadCargo e não tem um track
     * possível com eles, sempre utilizamos o último boleto anexado.
     */
    useEffect(() => {
        if (payments.length > 0) {
            if (payments.length === 1)
                setUrlPaymentSlip(payments[0].url)
            else {
                setUrlPaymentSlip(payments[payments.length - 1].url)
            }
        }

    }, [])


    /**
     * Como temos um monte de regra se o cara pode ou não pode baixar a fatura:
     * - É pagamento com boleto ? Se sim ele pode em qualquer data
     * - Não é pagamento com boleto, mas a data da fatura é de hoje ? Então pode
     * - A data é de ontem mas ainda não são 13 horas ? Então pode
     * - Em outros casos ele não pode fazer o download da fatura
     * @param {*} item 
     * @returns 
     */
    const handleDownloadPermission = (item) => {
        if (item.isPaymentSlip
            || moment(item.date).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")
            || (moment(item.date).format("DD/MM/YYYY") === moment().subtract(1, 'days').format("DD/MM/YYYY")
                && moment().format("hh") < 13)
        )
            return true
        return false
    }


    /**
     * Após o envio do arquivo o componente de upload devolve o link do arquivo.
     * Precisamos agora atualizar esse item financeiro com o comprovante.
     * @param {*} link 
     */
    const handleUpdateInvoiceReceipt = async () => {
        if (receipt.url !== '') {
            await updateReceipt(id, receipt)
                .then(r => {
                    toast.success("Comprovante atualizado. Obrigado")
                })
                .catch(error => {
                    console.error("Update receipt")
                    toast.error("O comprovante não pode ser atualizado.")
                })
        }

    }

    useEffect(() => {
        handleUpdateInvoiceReceipt()
    }, [receipt.url])


    return (
        <Row>
            <Col>
                <Card small className="mb-4 p-0">
                    <CardHeader className="border-bottom">
                        <SubTitle title="Financeiras" subtitle="Informações" className="col-md-12" />
                        <NavLink className="back-button" onClick={() => props.onClick('details')}>
                            <i className="material-icons text-muted ">keyboard_backspace</i>
                        </NavLink>
                    </CardHeader>
                    <CardBody className="pb-3">
                        <table className="table mb-0">
                            <thead className="bg-light">
                                <tr>
                                    <th scope="col" className="border-0">
                                        Vencimento
                                    </th>
                                    <th scope="col" className="border-0">
                                        Status
                                    </th>
                                    <th scope="col" className="border-0">
                                        Valor (R$)
                                    </th>
                                    <th scope="col" className="border-0 center-text">
                                        Ações
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {financials.map((invoice, index) => {
                                    return (
                                        <tr key={"invoice" + index} >
                                            <td><span className="text-muted" >{invoice.due ? moment.utc(invoice.due).format('DD/MM/YYYY') : '-'}</span></td>
                                            <td><span className="text-muted" >{invoice.isPayed ? 'Pago' : invoice.fileUpload ? 'Em análise' : 'Em aberto'}</span></td>
                                            <td><span className="text-muted" >{invoice.value && new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(invoice.value)}</span></td>
                                            <td className="action-column center-text">
                                                <Button disabled={!handleDownloadPermission(invoice)}
                                                    outline pill theme="secondary">
                                                    {handleDownloadPermission(invoice) ?
                                                        <a href={invoice.url} target="_blank" rel="noopener noreferrer" title="Download">
                                                            <i className="material-icons text-muted ">download</i>
                                                        </a>
                                                        :
                                                        <i className="material-icons text-muted ">download</i>
                                                    }
                                                </Button>
                                                <Button outline pill theme="secondary"
                                                    disabled={urlPaymentSlip === ''}>
                                                    <a href={urlPaymentSlip} target="_blank" rel="noopener noreferrer" title="Boleto bancário">
                                                        <i className="material-icons text-muted ">credit_card</i>
                                                    </a>
                                                </Button>
                                                {
                                                    (invoice.fileUpload || receipt.url) &&
                                                    <Button outline pill theme="secondary" title="Baixar comprovante" >
                                                        <a href={receipt.url ? receipt.url : invoice.fileUpload} target="_blank" rel="noopener noreferrer" title="Boleto bancário">
                                                            <i className="material-icons text-muted ">receipt</i>
                                                        </a>
                                                    </Button>
                                                }

                                                <Button outline pill theme="secondary" title="Enviar comprovante"
                                                    onClick={() => setReceipt({ ...receipt, show: !receipt.show, invoice: invoice._id })}>
                                                    <i className="material-icons text-muted ">upload</i>
                                                </Button>
                                                <Button outline pill theme="secondary" title="Solicitar revisão"
                                                    onClick={() => setReview({ ...review, askReview: !review.askReview })}>
                                                    <i className="material-icons text-muted ">refresh</i>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                        {review.askReview &&
                            <RequestValueReview responsible={props.responsible}
                                processNumber={props.processNumber}
                                cancelReview={() => setReview({ ...review, askReview: false })}
                                handleRiviewSent={() => setReview({ ...review, askReview: false, sent: true })} />
                        }

                        {receipt.show &&
                            <Uploader
                                getLink={(e) => {
                                    setReceipt({ ...receipt, show: false, url: e })
                                }}>
                            </Uploader>
                        }

                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}


export default Financials