import React from 'react'
import { Row, Col, Card, CardHeader, CardBody, Button, NavLink } from "shards-react";

import moment from 'moment'

import './DocumentApproval.style.css'

import { SendReviewRquest } from '../DetailsComponents';
import { SubTitle } from '../../common/CommonComponents';

const DocumentApproval = (props) => {

    const { issues, responsible, processNumber, refClient } = props

    const [review, setReview] = React.useState({
        askReview: false,
        sent: false,
        issueID: ''
    })

    const handleIssueStatus = (issue) => {
        if (issue.solved)
            return <span style={{ color: "green" }}>Aprovado</span>
        else if (moment(new Date()).diff(moment(issue.createdAt), 'hours') > 24)
            return <span style={{ color: "green" }}>Aprovado automáticamente</span>
        else return <span style={{ color: "red" }}>Aguardando aprovação</span>
    }


    return (
        <Row>
            {Array.isArray(issues) && issues.length > 0 &&
                <Col>
                    <Card small className="mb-4 p-0">
                        <CardHeader className="border-bottom">
                            <SubTitle title="Para aprovação" subtitle="Documentos" className="col-md-12" />
                        </CardHeader>
                        <CardBody className="pb-3">
                            <table className="table mb-0">
                                <tbody>
                                    {issues.reverse().map((issue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <span className="text-muted" >{issue.title}</span><br />
                                                    <span className="text-muted small" >{!review.sent ? handleIssueStatus(issue) : 'Revisão solicitada'}</span>
                                                </td>
                                                <td className="action-column">
                                                    <Button outline pill theme="secondary">
                                                        <NavLink href={issue.file} target="_blank" rel="noopener noreferrer" title="Download">
                                                            <i className="material-icons text-muted ">download</i>
                                                        </NavLink>
                                                    </Button>

                                                    <Button outline pill theme="secondary" title={issue.solved ? "Aprovado":"Aprovar"} disabled={issue.solved}>
                                                        <i className="material-icons text-muted ">check</i>
                                                    </Button>
                                                    <Button outline pill theme="secondary" title={issue.solved ? "Solicitar nova revisão":"Solicitar revisão"} onClick={() => setReview({ ...review, askReview: true, issueID: issue._id })}>
                                                        <i className="material-icons text-muted ">refresh</i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                            {review.askReview &&
                                <SendReviewRquest responsible={responsible}
                                    processNumber={processNumber}
                                    refClient={refClient}
                                    issueID={review.issueID}
                                    cancelReview={() => setReview({ ...review, askReview: false })}
                                    handleRiviewSent={() => setReview({ ...review, askReview: false, sent: true })} />
                            }
                        </CardBody>
                    </Card>
                </Col>
            }
        </Row>
    )
}


export default DocumentApproval