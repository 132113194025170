import React from 'react'

import { Bones } from "react-bones/native";

const Loading = ({ loading, number }) => {

    const [repeat] = React.useState(number ? number : 1)


    return (
        <React.Fragment>
            { [...Array(repeat)].map((e, i) => 
            <div key={'loading'+i}>
                <Bones />
                <div style={{ padding: 10 }} />
                <Bones width={600} height={20} />
                <div style={{ padding: 10 }} />
                <Bones width={600} height={20} />
                <div style={{ padding: 10 }} />
                <Bones width={600} height={20} />
                <div style={{ padding: 10 }} />
            </div >)

            }
        </React.Fragment>

    )

}



export default Loading