import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Card, CardBody } from "shards-react";


const CardTotals = ({variation, title, value}) => {

  const cardClasses = classNames(
    "stats-small",
    variation && `stats-small--${variation}`
  );

  const cardBodyClasses = classNames(
    variation === "1" ? "p-0 d-flex" : "px-0 pb-0"
  );

  const innerWrapperClasses = classNames(
    "d-flex",
    variation === "1" ? "flex-column m-auto" : "px-3"
  );

  const dataFieldClasses = classNames(
    "stats-small__data",
    variation === "1" && "text-center"
  );

  const labelClasses = classNames(
    "stats-small__label",
    "text-uppercase",
    variation !== "1" && "mb-1"
  );

  const valueClasses = classNames(
    "stats-small__value",
    "count",
    variation === "1" ? "my-3" : "m-0"
  );


  return (
    <Card small className={cardClasses}>
      <CardBody className={cardBodyClasses}>
        <div className={innerWrapperClasses}>
          <div className={dataFieldClasses}>
            <span className={labelClasses}>{title}</span>
            <h6 className={valueClasses}>{value}</h6>
          </div>
        </div>
      </CardBody>
    </Card>
  );

}


CardTotals.propTypes = {
  variation: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};


CardTotals.defaultProps = {
  variation: 1,
  value: 0,
  title: "Título",
  
};

export default CardTotals
