import React from "react";
import { Container, Row, Col } from "shards-react";

const AuthLayout = ({ children }) => (
  <Container fluid>
    <Row>
      <Col
        className="main-content p-0"
        lg={{ size: 12 }}
        md={{ size: 12 }}
        sm="12"
        tag="main"
        style={{margin: "0 auto"}}
      >
        {children}
      </Col>
    </Row>
  </Container>
);

export default AuthLayout;
