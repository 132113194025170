import api from "./api";


export const getProcesses = async (query, sort, page, limit) => {
    return api.get(`cargoprocesses/?q=${query}&sort=${sort}&_page=${page}&_pageSize=${limit ? limit : 999}`);
}


export const getOneProcess = async (processID) => {
    return api.get(`cargoprocesses/${processID}`)
}

export const sendReview = async (data) => {
    return await api.post(`email/send/docs_feedback`, data)
}
