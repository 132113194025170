import React from 'react'

import { ToastContainer, toast } from 'react-toastify';
import { Button, Col, FormTextarea, Row } from 'shards-react';
import { requestValeuReview, retrieveUserInformation } from '../../../../services';

import './RequestValueReview.style.css'

const RequestValueReview = (props) => {

    const [comments, setComments] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    

    const handleRequestValueReview = async (description) => {
        setLoading(true)
        let data = {
            name: retrieveUserInformation().name,
            email: retrieveUserInformation().email,
            processNumber: props.processNumber,
            description: description
        }

        await requestValeuReview(data)
            .then(r => {
                setLoading(false)
                toast.success("Solicitação de revisão enviada. Obrigado!")
                setComments('')
            })
            .catch(e => {
                setLoading(false)
                toast.error("Erro enviando sua solicitação")

            })

        }


    return (
        <React.Fragment>
            <ToastContainer />
            <React.Fragment >
                <Row>
                    <Col>
                        <FormTextarea placeholder="Informe as correções necessárias"
                            disabled={loading}
                            rows="5" onChange={(e) => setComments(e.target.value)}
                            className="review-content"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="review-buttons">
                        <Button disabled={loading}
                        onClick={() => handleRequestValueReview(comments)}>
                            Enviar solicitação
                    </Button>
                        <Button theme="secondary" onClick={() => props.cancelReview()}>
                            Cancelar
                    </Button>
                    </Col>
                </Row>
            </React.Fragment>
        </React.Fragment>
    )

}


export default RequestValueReview