import React, { useEffect } from "react";
import { retrieveUserInformation } from "../../../../services";

const UserActions = (props) => {

  const [userInfo, setUserInfo] = React.useState({
    name: '',
    imagem: '',

  })

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      name: retrieveUserInformation().name
    })
  }, [])


  /**
   * Gerador de avatar baseado nas primeiras letras do nome.
   * Foi baseada na resposta do usuário schnoedel no post
   * https://stackoverflow.com/questions/39723672/how-to-display-avatar-icon-with-dynamically-created-name-initials
   * 
   * A vantagem dele é que realmente gera uma imagem.
   * 
   * @param {*} name 
   * @returns 
   */
  function generateAvatar(name) {
    var initials = name.split(' ').slice(0,2).map(function (str) { return str ? str[0].toUpperCase() : ""; }).join('');
    var canvas = document.createElement('canvas');
    var radius = 30;
    var margin = 5;
    canvas.width = radius * 2 + margin * 2;
    canvas.height = radius * 2 + margin * 2;

    // Get the drawing context
    var ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.arc(radius + margin, radius + margin, radius, 0, 2 * Math.PI, false);
    ctx.closePath();
    ctx.fillStyle = '#6f2c91';
    ctx.fill();
    ctx.fillStyle = "white";
    ctx.font = "bold 28px Arial";
    ctx.textAlign = 'center';
    ctx.fillText(initials, radius + 5, radius * 4 / 3 + margin);
    return canvas.toDataURL();

  }

  return (
    <div className="p-2 justify-content-center"
      style={{ display: 'flex', minWidth: "180px", alignItems: "center" }}>
      <img
        className="user-avatar rounded-circle mr-2"
        src={generateAvatar(userInfo.name)}
        alt="Imagem do usuário"
      />{" "}
      <span className="d-none d-md-inline-block" title={`Olá, ${userInfo.name}`}>
        {userInfo.name.length < 12 ? userInfo.name : userInfo.name.slice(0, 11) + "..."}
      </span>
    </div>

  );
}

export default UserActions