export default function() {
  return [
    {
      title: "Visão Geral",
      to: "/overview",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title: "Sair",
      htmlBefore: '<i class="material-icons">logout</i>',
      to: "/login",
    },
     ];
}
