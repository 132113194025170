import React from 'react'
import { Row, Col, FormTextarea, Button } from "shards-react";
import { ToastContainer, toast } from 'react-toastify';

import { retrieveUserInformation, sendReview } from '../../../../services';

import './SendReviewRequest.style.css'

const SendReviewRquest = (props) => {

    const [comments, setComments] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const handleSendReview = async () => {
        setLoading(true)
        let data = {
            issueID: props.issueID,
            processNumber: props.processNumber,
            status: "Reprovado",
            comments: comments,
            to: `gutemberg.pereira@nextshipping.com.br,${props.responsible !== undefined ? props.responsible.email : ''}`,
            cc: retrieveUserInformation().email,
            subject: `[${props.processNumber} | ${props.refClient}] Feedback de documentos`,
            responsible: props.responsible !== undefined ? props.responsible.name : 'João Mendonça'
        }
        await sendReview(data)
            .then((data) => {
                toast.success("Solicitação de revisão enviada. Obrigado!")
                setLoading(false)
                setTimeout(() => {
                    props.handleRiviewSent()
                }, 2500)
            })
            .catch(error => {
                toast.error("Erro enviando sua solicitação")
                setLoading(false)
                console.log(error)
            })

    }


    return (
        <React.Fragment>
            <ToastContainer />
            <React.Fragment >
                <Row>
                    <Col>
                        <FormTextarea placeholder="Informe as correções necessárias"
                            disabled={loading}
                            rows="5" onChange={(e) => setComments(e.target.value)}
                            className="review-content"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="review-buttons">
                        <Button disabled={loading} onClick={() => handleSendReview()}>
                            Enviar solicitação
                    </Button>
                        <Button theme="secondary" onClick={() => props.cancelReview()}>
                            Cancelar
                    </Button>
                    </Col>
                </Row>
            </React.Fragment>
        </React.Fragment>
    )
}




export default SendReviewRquest