import React from 'react'
import { Row, Col} from "shards-react";
import moment from "moment";
import { DetailItem } from '../../DetailsComponents';
import { SubTitle } from '../../../common/CommonComponents';

const Cargo = (props) => {

    const { info } = props

    return (
        <>
            <SubTitle title="Carga" subtitle={<hr />} />
            <Row>
                <Col md="4" sm="6" xs="12" >
                    <DetailItem title="Tipo de carga" info={info.cargo.type} />
                </Col>
            </Row>
            <SubTitle md="12" title="Equipamentos" className="h6-subtitle" />
            <Row>
                {info.knowledge && info.knowledge.equipaments &&
                    info.knowledge.equipaments.map((equip, index) => (
                        <React.Fragment key={index}>
                            <Col md="3" sm="6" xs="12" >
                                <DetailItem className="p-mb-0 p-mt-2"
                                    title="Tipo" info={equip.type} />
                            </Col>
                            <Col md="3" sm="6" xs="12" >
                                <DetailItem className="p-mb-0 p-mt-2"
                                    title="Quantidade" info={equip.quantity} />
                            </Col>
                            <Col md="3" sm="6" xs="12" >
                                <DetailItem className="p-mb-0 p-mt-2"
                                    title="Free Time" info={equip.freeTimeDestination} />
                            </Col>
                            <Col md="3" sm="6" xs="12" >
                                <DetailItem className="p-mb-0 p-mt-2"
                                    title="Devolução" info={moment.utc(equip.devolutionLimit).format("DD MMM")} />
                            </Col>
                        </React.Fragment>
                    ))}
            </Row>
        </>
    )

}


export default Cargo