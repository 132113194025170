import axios from "axios";

export const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
});


api.interceptors.request.use(async config => {
    const token = localStorage.getItem("cpb-token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});


export default api