import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

// Layout Types
import { AuthLayout, DefaultLayout } from "./layouts";

// Route Views
import Overview from "./pages/Overview/Overview";
import Login from "./pages/auth/Login.component";
import ProcessDetails from "./pages/ProcessDetails/ProcessDetails";
import { isAuthenticated } from "./services";



const PrivateRoutes = ({ component: Component, layout: Layout, ...rest }) => (
  < Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              from: props.location
            }
          }}
        />
      )
    }
  />
);


const AppRoutes = () => {
  return (
    <BrowserRouter >
      <Switch>
      <Route exact path="/" layout={AuthLayout} component={Login} />
        <Route exact path="/login" layout={AuthLayout} component={Login} />
        <PrivateRoutes
          exact
          path="/overview"
          layout={DefaultLayout}
          component={Overview}
        />
        <PrivateRoutes
          exact
          path="/details/:id"
          layout={DefaultLayout}
          component={ProcessDetails}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default AppRoutes