import React from 'react'
import { useHistory } from "react-router-dom";
import moment from 'moment'
import 'moment/locale/pt-br';
import { Card, CardBody, Badge, CardFooter } from "shards-react";

import './ProcessCard.style.scss'

const ProcessCard = (props) => {
    let history = useHistory();

    const { process } = props


    return (
        <Card small className="card-post mb-4" onClick={() => history.push(`/details/${process._id}`)}>
            <CardBody>
                <h5 className="card-title client-reference">{process.clientReference}</h5>
                <p className="card-text text-muted">{process.body}</p>
                <div className="origin-destination">
                    <div >
                        <span className="text-uppercase subtitle">Origem</span>
                        <h6 className="title">{process.boarding.origin}</h6>
                    </div>
                    <i className="material-icons transportation-icon">directions_boat</i>
                    <div className="align-right">
                        <span className="text-uppercase subtitle">Destino</span>
                        <h6 className="title">{process.boarding.destination}</h6>
                    </div>
                </div>
            </CardBody>
            <CardFooter className="border-top d-flex">
                <div className="card-post__author d-flex">
                    <div className="d-flex flex-column justify-content-center">
                        <span className="card-post__author-name">
                            {process.clientName}
                        </span>
                        <small className="text-muted" title="ETA">ETA {process.boarding.estimatedCargoUnload ? moment.utc(process.boarding.estimatedCargoUnload).format("DD MMM YYYY") : "-"}</small>
                        <Badge
                            pill
                            className={`card-post__category bg-info`}
                        >
                            Aguardando {process.microStatus}
                        </Badge>
                    </div>
                </div>
            </CardFooter>
        </Card>
    )
}


export default ProcessCard