import React from 'react'
import './Login.style.css'

import { useHistory } from "react-router-dom";

import {
    Container,
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Form,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormInput,
    Button,
    NavLink
} from "shards-react";


import { signIn } from '../../services';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistUserToLocalStorage } from '../../services';


const Login = () => {

    const [userInfos, setUserInfos] = React.useState({
        email: '',
        password: ''
    })
    const [loading, setLoading] = React.useState(false)


    let history = useHistory();

    const login = async () => {
        setLoading(true)
        if (!loading) {
            await signIn(userInfos)
                .then(res => {
                    setLoading(false)
                    persistUserToLocalStorage(res.data)
                    .then(()=> {
                        toast.success("Login realizado. Aguarde enquanto redirecionamos você;")
                        setTimeout(() => {
                            history.push('/overview')
                        }, 2500)
                    })
                    .catch(error => console.error(error))
                })
                .catch(e => {
                    setLoading(false)
                    console.error("ERRO LOGIN", e)
                    toast.error("Usuário ou senha incorretos")
                })
        }

    }

    return (
        <Container fluid className="main-content-container px-4">
            <ToastContainer />
            <section id="cover" className="min-vh-100">
                <div id="cover-caption">
                    <div className="container">
                        <div className="row text-white">
                            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
                                <div className="px-2">
                                    <Card small className="mb-4 card-extras" style={{ marginTop: '45%' }}>
                                        <CardHeader className="border-bottom" style={{ textAlign: "center" }}>
                                            <img
                                                id="main-logo"
                                                className="d-inline-block align-top mr-1"
                                                style={{ maxWidth: "70%", maxHeight: "80%" }}
                                                src={require("../../images/cargo-play-logo.svg")}
                                                alt="Cargo Play"
                                            />
                                        </CardHeader>

                                        <ListGroup flush>
                                            <ListGroupItem className="px-3">
                                                <Form style={{ textAlign: "center" }}>
                                                    <strong className="text-muted d-block mb-2">
                                                        <h4>Bem-vindo!</h4> Informe seu usuário e senha para continuar.
                                                    </strong>
                                                    <div style={{ padding: "32px 16px" }}>
                                                        <InputGroup seamless className="mb-3">
                                                            <InputGroupAddon type="prepend">
                                                                <InputGroupText>
                                                                    <i className="material-icons">person</i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <FormInput
                                                                type="text"
                                                                value={userInfos.email}
                                                                onChange={(e) => setUserInfos({ ...userInfos, email: e.target.value })}
                                                            />
                                                        </InputGroup>

                                                        <InputGroup seamless className="mb-3">
                                                            <InputGroupAddon type="prepend">
                                                                <InputGroupText>
                                                                    <i className="material-icons">lock</i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <FormInput
                                                                type="password"
                                                                value={userInfos.password}
                                                                onChange={(e) => setUserInfos({ ...userInfos, password: e.target.value })}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                    <div className="auth-buttons mb-2">
                                                        <Button theme="primary" className="mb-2 mr-1"
                                                            onClick={(e) => {
                                                                login()
                                                            }} >
                                                            Acessar
                                                        </Button>
                                                    </div>
                                                    <div className="auth-request text-muted d-block mb-2">
                                                        <span className="small">Novo(a) por aqui? <NavLink href="#"> Solicite o seu acesso.</NavLink></span>
                                                    </div>
                                                </Form>
                                            </ListGroupItem>
                                        </ListGroup>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Container>
    )



}


export default Login


