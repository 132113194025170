import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import './Uploader.style.scss'


import { Button, Col, Row } from "shards-react";
import { upload } from "../../../services";
import { toast, ToastContainer } from "react-toastify";

export default (props) => {

    const [loading, setLoading] = React.useState(false);


    const onDrop = useCallback(async (acceptedFiles) => {
        setLoading(true)
        const uploadData = new FormData();
        uploadData.append('image', acceptedFiles[0])
        toast.info("Aguarde. Estamos enviando seu arquivo.")
        await upload(uploadData)
            .then(r => {
                toast.success("Arquivo enviado. Obrigado",
                    {
                        onClose: () => {
                            props.getLink(r.data.link)
                            setLoading(false)
                        }
                    })
            })
            .catch(e => {
                setLoading(false)
                console.log("Erro de upload", e)
                toast.error("Não foi possível enviar seu arquivo.")
            })
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Row>
            <ToastContainer />
            <Col>
                <div className="uploader">
                    <div {...getRootProps()} className="drop-area">
                        <input {...getInputProps()} disabled={loading} />
                        <i className="material-icons text-muted">upload</i>
                        <p>Arraste e solte seu arquivo aqui</p>
                        <p>ou</p>
                        <Button disabled={loading} variant="outlined" className="send-button">
                            Escolha seu arquivo
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>

    )
}