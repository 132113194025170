import React, { useEffect } from 'react'
import {
    Row,
    Col
} from "shards-react";
import ProcessCard from './ProcessCard/ProcessCard.component'
import './Kanban.style.css'


const Kanban = (props) => {

    const { processes } = props

    const [filteredProcesses, setFilteredProcesses] = React.useState({
        pre: [],
        transito: [],
        desembarcado: []
    })


    useEffect(() => {
        console.log("Kanban")
        setFilteredProcesses({
            ...filteredProcesses,
            pre: processes.filter(p => { return p.kanbanStatus === 'Pré embarque' }),
            transito: processes.filter(p => { return p.kanbanStatus === 'Em trânsito' }),
            desembarcado: processes.filter(p => { return p.kanbanStatus === 'Desembarcado' })
        })

    }, [props.processes])

    return (
        <Row>
            <Col lg="4" >
                {filteredProcesses && filteredProcesses.pre.length > 0 &&
                    filteredProcesses.pre.map((process) => (
                        <ProcessCard process={process} key={'process' + process._id} />
                    ))
                }
            </Col>
            <Col lg="4" >
                {filteredProcesses && filteredProcesses.transito.length > 0 &&
                    filteredProcesses.transito.map((process) => (
                        <ProcessCard process={process} key={'process' + process._id} />
                    ))
                }
            </Col>
            <Col lg="4" >
                {filteredProcesses && filteredProcesses.desembarcado.length > 0 &&
                    filteredProcesses.desembarcado.map((process) => (
                        <ProcessCard process={process} key={'process' + process._id} />
                    ))

                }
            </Col>
        </Row>
    )
}


export default Kanban